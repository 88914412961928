import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="レギュレーション - イラストレーション" mdxType="SEO" />
    <PageTitle title="レギュレーション" enTitle="Regulation" mdxType="PageTitle" />
    <h2 {...{
      "id": "グラフィックスタイル",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%B0%E3%83%A9%E3%83%95%E3%82%A3%E3%83%83%E3%82%AF%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%AB",
        "aria-label": "グラフィックスタイル permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`グラフィックスタイル`}</h2>
    <p>{`イラストの基本的なスタイルとして、カラーと線の規定を作成しています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/663800addc490ef651ae2ae8d9f8f3c9/21b4d/tHKOZ-2347%3A42410.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABUUlEQVQoz62QT0sCURTFZ9OiRbugJJcR9K36BC3atRFctdNv0MLBkQEnXCQIklAQuckktKSIomje/FFnfG9G1Jl5J+aZgxItgi4c3uHA/b17r8Q8BmISeL6HkEfgnIPzCBEPE8VZGIYIgkAo9ss17+HCS9PxFKxPgQB/qsUnC9Aikzp6D0qzjEq7ilrvAuPZBM/GDS4fC2h9VPDUbyAIZ9C0M8iyjGKxiHq9LgAjShNoMmGmdoL14x2ksvvYzOzidfCJ0v0BDs/XcFTdQLaxB+oPsL2VgiRJQul0emXVFWBb70C+VaG0ykJs4uNlcIXrt1M03wu40zXMggkUpYR8Po9cLgdVVUVzFEU/gYjvy/EvJW5IDILuQxdDZwiXumCMgdIRRtT51jxzHAeU0sTHr+d5yWTJhL7vw7ZtmKYJwzB+FSFEgFzXFT7OLMsSay8DvwDjnEdSKA2ynAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/663800addc490ef651ae2ae8d9f8f3c9/2aaaf/tHKOZ-2347%3A42410.webp 160w", "/static/663800addc490ef651ae2ae8d9f8f3c9/85e47/tHKOZ-2347%3A42410.webp 320w", "/static/663800addc490ef651ae2ae8d9f8f3c9/75198/tHKOZ-2347%3A42410.webp 640w", "/static/663800addc490ef651ae2ae8d9f8f3c9/691bc/tHKOZ-2347%3A42410.webp 960w", "/static/663800addc490ef651ae2ae8d9f8f3c9/223e5/tHKOZ-2347%3A42410.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/663800addc490ef651ae2ae8d9f8f3c9/69538/tHKOZ-2347%3A42410.png 160w", "/static/663800addc490ef651ae2ae8d9f8f3c9/72799/tHKOZ-2347%3A42410.png 320w", "/static/663800addc490ef651ae2ae8d9f8f3c9/6af66/tHKOZ-2347%3A42410.png 640w", "/static/663800addc490ef651ae2ae8d9f8f3c9/d9199/tHKOZ-2347%3A42410.png 960w", "/static/663800addc490ef651ae2ae8d9f8f3c9/21b4d/tHKOZ-2347%3A42410.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/663800addc490ef651ae2ae8d9f8f3c9/6af66/tHKOZ-2347%3A42410.png",
              "alt": "Colors: Ameba green, Yellow green, Black, White。Lines: Blackの線",
              "title": "Colors: Ameba green, Yellow green, Black, White。Lines: Blackの線",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "カラー",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%AB%E3%83%A9%E3%83%BC",
        "aria-label": "カラー permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`カラー`}</h2>
    <p>{`イラストに使用するカラーはブランド全体のカラースキームからピックアップしています。 使用色を絞ることでイラスト全体での統一感を図っています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b224d6133cfabbfe14aa26b9de218fa3/21b4d/tHKOZ-2347%3A42466.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABKElEQVQY04WRvUoDQRSFt/QVRLCxEh9JLEynhVUgNv4kNiLY+QiKhWLENDbRwiJgBBM3qBCQGCRFXNYiO0tmZmc+mdFEU4gHzlwY7px77pmAv2D4F0MpMear0Vrra+COw9sT1stFSpd7nD5UECrmvFmk3Nik8rhFpJ+4rt6Qyy2Tz+cpFArEcYxUCimlFxvRCy4drzCzs8B0aZ7Fo1Ui8cL21Rwb1VnWLqZ4Ts7Y3z0gCIIx2+32hNsJwU7U5f41pNFt8fbRIzOabtSi02/SiZpIO+C9H1Gr1ajX7wjDEK21d5cIQZqm49W9oJaaoRhi5E8eaaIQA4nR35Fa4zMbZeUghCBJBEqpyQzdNKWVfzSyrpT0Tq11QyxZlnlHzomj63F3vz/FrfwJvGexuwtbvZ8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b224d6133cfabbfe14aa26b9de218fa3/2aaaf/tHKOZ-2347%3A42466.webp 160w", "/static/b224d6133cfabbfe14aa26b9de218fa3/85e47/tHKOZ-2347%3A42466.webp 320w", "/static/b224d6133cfabbfe14aa26b9de218fa3/75198/tHKOZ-2347%3A42466.webp 640w", "/static/b224d6133cfabbfe14aa26b9de218fa3/691bc/tHKOZ-2347%3A42466.webp 960w", "/static/b224d6133cfabbfe14aa26b9de218fa3/223e5/tHKOZ-2347%3A42466.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b224d6133cfabbfe14aa26b9de218fa3/69538/tHKOZ-2347%3A42466.png 160w", "/static/b224d6133cfabbfe14aa26b9de218fa3/72799/tHKOZ-2347%3A42466.png 320w", "/static/b224d6133cfabbfe14aa26b9de218fa3/6af66/tHKOZ-2347%3A42466.png 640w", "/static/b224d6133cfabbfe14aa26b9de218fa3/d9199/tHKOZ-2347%3A42466.png 960w", "/static/b224d6133cfabbfe14aa26b9de218fa3/21b4d/tHKOZ-2347%3A42466.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/b224d6133cfabbfe14aa26b9de218fa3/6af66/tHKOZ-2347%3A42466.png",
              "alt": "Ameba green, Yellow green, Black, White",
              "title": "Ameba green, Yellow green, Black, White",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "線",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E7%B7%9A",
        "aria-label": "線 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`線`}</h2>
    <p>{`イラストの大きさと線の太さの見え方を統一するため、人物イラストの大きさに合わせて線幅を調整します。イラストシンボル化時に考慮します。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7cc9d43d7a8c30a435e784c71ac4a8af/21b4d/tHKOZ-2347%3A42484.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABIUlEQVQoz41RQUsCQRTeez+h/9AfqHv/wXv3vAR58BDoL5AuBkFQdEgwSsuLh+ggIaFgREKlEjrSrrpLkzO7O/PFjjWN1VYfDPPmve+99715Ft4hpVQnQrV1gWa3pWwhBUzOX7BM4se9W95D6aqiC776U/ihrzlCiG85cwXNAOMMifwactW8Jp3WzlBvX88pNif6UWEoQuUo1ypYSC5iJbsKSqnyJQ9SOKofK5v7HB3Sjf0G62s3Z+Rg+3wHxcsTQALkmWBpaxkbh2kV79lPSBcyWkDsyGZwPByBejN1IhS4u2+DDIl63/Rusb6/CTql/1PIOUej2UB/0NcJnYdHeJ43izMOm9ifxSR+X0p0u64LxpgmTdyJahQhCAK8UBq7mDcE+WFDVMaNwgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7cc9d43d7a8c30a435e784c71ac4a8af/2aaaf/tHKOZ-2347%3A42484.webp 160w", "/static/7cc9d43d7a8c30a435e784c71ac4a8af/85e47/tHKOZ-2347%3A42484.webp 320w", "/static/7cc9d43d7a8c30a435e784c71ac4a8af/75198/tHKOZ-2347%3A42484.webp 640w", "/static/7cc9d43d7a8c30a435e784c71ac4a8af/691bc/tHKOZ-2347%3A42484.webp 960w", "/static/7cc9d43d7a8c30a435e784c71ac4a8af/223e5/tHKOZ-2347%3A42484.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7cc9d43d7a8c30a435e784c71ac4a8af/69538/tHKOZ-2347%3A42484.png 160w", "/static/7cc9d43d7a8c30a435e784c71ac4a8af/72799/tHKOZ-2347%3A42484.png 320w", "/static/7cc9d43d7a8c30a435e784c71ac4a8af/6af66/tHKOZ-2347%3A42484.png 640w", "/static/7cc9d43d7a8c30a435e784c71ac4a8af/d9199/tHKOZ-2347%3A42484.png 960w", "/static/7cc9d43d7a8c30a435e784c71ac4a8af/21b4d/tHKOZ-2347%3A42484.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/7cc9d43d7a8c30a435e784c71ac4a8af/6af66/tHKOZ-2347%3A42484.png",
              "alt": "人物が大きくなれば線が太くなり、小さくなれば細くなる",
              "title": "人物が大きくなれば線が太くなり、小さくなれば細くなる",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "キャラの規定",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%AD%E3%83%A3%E3%83%A9%E3%81%AE%E8%A6%8F%E5%AE%9A",
        "aria-label": "キャラの規定 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`キャラの規定`}</h2>
    <p>{`イラストの新規作成時、全体の統一性やクオリティを担保するために下記規定をお守りください。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5342d977e1fc4ebeb379643f9db52038/21b4d/tHKOZ-2347%3A42660.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABVklEQVQoz2WQTUsDMRCG/VHiWfQHeBe8Cf4QBW+Chx4Uil4UFKEHlfYgVETQVvFgkVbUtljddrvdr+y6u9lN8kpm6Yc4EHiSSZ7MzJxSCjqkEPA/XiGzjPbssw2RcuLQNJA4FjH3HISDb2KRpWC9NpSUtNeuubHQ/2EolIuIeQKechQqRdjModzZ3TluWzXip+4zKo0qsRO6OK6VkIpsKpQqt3cGXcxvLqJjdsFChoWtJdTfHym3ureO7csd4v2bQ6wdbBC3+m9Y3l2BxUZ5l7MVOszBUfUEDnMR85j4y8pbu6iXcd98IH7pNXHVuCbWotNaCUEU/G9ZCgnLyOdEH5g2kKcQuAF4lM8TQkGlcnIPXE1kOqZCKRHFUX6Hcxh9A0mSIE1T9Ad9uJ5Lj0b2CLZjEzPGYA6HEEJMK5y1j0NLPM8jsV6awzCkT33fJ5Fmfea67h/hL/BaDcz92JjwAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/5342d977e1fc4ebeb379643f9db52038/2aaaf/tHKOZ-2347%3A42660.webp 160w", "/static/5342d977e1fc4ebeb379643f9db52038/85e47/tHKOZ-2347%3A42660.webp 320w", "/static/5342d977e1fc4ebeb379643f9db52038/75198/tHKOZ-2347%3A42660.webp 640w", "/static/5342d977e1fc4ebeb379643f9db52038/691bc/tHKOZ-2347%3A42660.webp 960w", "/static/5342d977e1fc4ebeb379643f9db52038/223e5/tHKOZ-2347%3A42660.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/5342d977e1fc4ebeb379643f9db52038/69538/tHKOZ-2347%3A42660.png 160w", "/static/5342d977e1fc4ebeb379643f9db52038/72799/tHKOZ-2347%3A42660.png 320w", "/static/5342d977e1fc4ebeb379643f9db52038/6af66/tHKOZ-2347%3A42660.png 640w", "/static/5342d977e1fc4ebeb379643f9db52038/d9199/tHKOZ-2347%3A42660.png 960w", "/static/5342d977e1fc4ebeb379643f9db52038/21b4d/tHKOZ-2347%3A42660.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/5342d977e1fc4ebeb379643f9db52038/6af66/tHKOZ-2347%3A42660.png",
              "alt": "禁止事項: 口鼻を加えない・目の形状を変えない・線の色を変えない・線幅を変えない",
              "title": "禁止事項: 口鼻を加えない・目の形状を変えない・線の色を変えない・線幅を変えない",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "キャラ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%AD%E3%83%A3%E3%83%A9",
        "aria-label": "キャラ permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`キャラ`}</h3>
    <p>{`Amebaらしさを様々な場面で表現するために利用します。 年齢や性別などを考慮しながら、多様性が感じられるかを意識します。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f602374f02c06da613644d2819804e2a/21b4d/tHKOZ-2347%3A43652.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABJ0lEQVQoz3VRXU/CMBTdH/c/+OYfICQmPpoIaHzQxBAJIZgIqwMcH+4Dih1lhW5rewybDFQ8yWl6z705vb3XwjeMMTlPxf/ph1iXd0tmCXbcg/EQMt0AZl9c6ForROt5aZgq+cd4B6v11sZ76JYJZ0wQcVbGI99B22khy1K89Dul7oUutDIQSQyfeYcO+2OCkC2wEgwDn4BMBkhNhka9AdIb4vXjEee1M2xlhqb9jHFoY71hIG4HWgPd0RNqzWphCAMrYAEiEWO66OG+W8kTXHzi+u4Ks6mHSATwl07ZWfXhAjNqw6ceZCpx27zBZb0CNyh+ae2OWMQYTYagS5qLSivwDcvf/D2jOaXg8RrH2CYSK8ELw/2QlVLQRp/c6PEWtdY5D7mf9V8tLxkCrEfvwgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f602374f02c06da613644d2819804e2a/2aaaf/tHKOZ-2347%3A43652.webp 160w", "/static/f602374f02c06da613644d2819804e2a/85e47/tHKOZ-2347%3A43652.webp 320w", "/static/f602374f02c06da613644d2819804e2a/75198/tHKOZ-2347%3A43652.webp 640w", "/static/f602374f02c06da613644d2819804e2a/691bc/tHKOZ-2347%3A43652.webp 960w", "/static/f602374f02c06da613644d2819804e2a/223e5/tHKOZ-2347%3A43652.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f602374f02c06da613644d2819804e2a/69538/tHKOZ-2347%3A43652.png 160w", "/static/f602374f02c06da613644d2819804e2a/72799/tHKOZ-2347%3A43652.png 320w", "/static/f602374f02c06da613644d2819804e2a/6af66/tHKOZ-2347%3A43652.png 640w", "/static/f602374f02c06da613644d2819804e2a/d9199/tHKOZ-2347%3A43652.png 960w", "/static/f602374f02c06da613644d2819804e2a/21b4d/tHKOZ-2347%3A43652.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f602374f02c06da613644d2819804e2a/6af66/tHKOZ-2347%3A43652.png",
              "alt": "本を読んだり、犬の散歩したり、自転車に乗ったりしている人々のイラスト",
              "title": "本を読んだり、犬の散歩したり、自転車に乗ったりしている人々のイラスト",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "ライセンス",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%A9%E3%82%A4%E3%82%BB%E3%83%B3%E3%82%B9",
        "aria-label": "ライセンス permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`ライセンス`}</h2>
    <p>{`Ameba Illustrationの権利はAmebaが保有しています。いかなる理由でも再利用・改変・再配布はできません。`}</p>
    <h2 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Example`}</h2>
    <p>{`イラストを使用する際の事例です。使用する際の参考にしてください。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/76a19b8b368b3cac1f3321d5e7611bec/21b4d/tHKOZ-2347%3A45152.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.37499999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/76a19b8b368b3cac1f3321d5e7611bec/2aaaf/tHKOZ-2347%3A45152.webp 160w", "/static/76a19b8b368b3cac1f3321d5e7611bec/85e47/tHKOZ-2347%3A45152.webp 320w", "/static/76a19b8b368b3cac1f3321d5e7611bec/75198/tHKOZ-2347%3A45152.webp 640w", "/static/76a19b8b368b3cac1f3321d5e7611bec/691bc/tHKOZ-2347%3A45152.webp 960w", "/static/76a19b8b368b3cac1f3321d5e7611bec/223e5/tHKOZ-2347%3A45152.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/76a19b8b368b3cac1f3321d5e7611bec/69538/tHKOZ-2347%3A45152.png 160w", "/static/76a19b8b368b3cac1f3321d5e7611bec/72799/tHKOZ-2347%3A45152.png 320w", "/static/76a19b8b368b3cac1f3321d5e7611bec/6af66/tHKOZ-2347%3A45152.png 640w", "/static/76a19b8b368b3cac1f3321d5e7611bec/d9199/tHKOZ-2347%3A45152.png 960w", "/static/76a19b8b368b3cac1f3321d5e7611bec/21b4d/tHKOZ-2347%3A45152.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/76a19b8b368b3cac1f3321d5e7611bec/6af66/tHKOZ-2347%3A45152.png",
              "alt": "テキストやボタンにイラストが添えられている",
              "title": "テキストやボタンにイラストが添えられている",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      